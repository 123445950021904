import { useState, useEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import CardBox from "../../../../components/Common/CardBox";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "../../../../constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "../../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { DTColProps, ErrorMessageHandler, ErrorCatchValidator, DataTableColumnRender } from "../../../../utils/Common";
import { ToObjectWithKey } from "../../../../utils/array";
import { DefaultIfEmpty } from "../../../../utils/object";

export interface SymbolAssetTypeListProps {}

interface SymbolAssetType {
    symbolAssetTypeId: number;
    symbolAssetType: string;
    createdBy: string;
    createdDateUtc: string;
    updateBy: string | number | null;
    updateTimeUtc: string | null;
}

const SymbolAssetTypeList = (props: SymbolAssetTypeListProps) => {
    let navigate = useNavigate();
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.SYMBOL_ASSET_TYPE_EDIT);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<SymbolAssetType[]>([]);
    const [userObj, setUserObj] = useState<any>({});

    const columns: any[] = useMemo(
        () => [
            {
                title: "Symbol Asset Type",
                dataIndex: "symbolAssetType",
                key: "symbolAssetType",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            },
            DTColProps.Small({
                title: "Last Updated By",
                dataIndex: "updateBy",
                key: "updateBy",
                render: (value: string, rowData: SymbolAssetType) =>
                    rowData.updateBy === null ? DefaultIfEmpty(userObj, rowData.createdBy, "") : DefaultIfEmpty(userObj, rowData.updateBy, ""),
            }),

            DTColProps.DateTime({
                width: "12.5vw",
                title: "Last Updated Time (Local)",
                dataIndex: "updateTimeUtc",
                key: "updateTimeUtc",
                render: (value: string, rowData: SymbolAssetType) =>
                    DataTableColumnRender.DateTime(rowData.updateTimeUtc === null ? rowData.createdDateUtc : rowData.updateTimeUtc),
                sorter: (a: any, b: any) => (a.updateTimeUtc < b.updateTimeUtc ? -1 : 1),
            }),
        ],
        [userObj]
    );

    const options: FlexiDataTableOptionsProps = {
        add: () => {
            if (enableUpdate) {
                return "/siteadmin/symbolconfig/assettype/create";
            } else return enableUpdate;
        },
        edit: (record: any, overwriteProps: any) => {
            if (enableUpdate) {
                let newProps = { ...overwriteProps };
                newProps.label = <Link to={`/siteadmin/symbolconfig/assettype/edit/${record.symbolAssetTypeId}`}>{newProps.label}</Link>;
                return newProps;
            } else return enableUpdate;
        },
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, assetType) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/symbolconfig/assettype/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate(`/siteadmin/symbolconfig/assettype/edit/${assetType.symbolAssetTypeId}`, {
                    state: { data: assetType.symbolAssetTypeId, action: "edit" },
                });
                break;
            case CALLBACK_KEY.DO_DELETE:
                apiRequest(APIs.DELETE_SYMBOL_ASSET_TYPE, {
                    symbolAssetTypeId: assetType.symbolAssetTypeId,
                })
                    .then(data => {
                        ErrorMessageHandler(assetType.symbolAssetType, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        getListing();
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol asset type", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    );
                break;
            default:
                break;
        }
    };

    const getListing = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_SYMBOL_ASSET_TYPE_LISTING, {})
            .then((res: SymbolAssetType[]) => {
                setData(res);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol asset type", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    const getConfig = () => {
        apiRequest(APIs.GET_USER_PROFILES, { isBinded: true })
            .then(data => {
                setUserObj(ToObjectWithKey([{ id: 0, firstName: "System" }, ...data.filter((x: any) => x.id !== 0)], "id", "firstName"));
            })
            .finally(() => getListing());
    };

    useEffect(() => {
        getConfig();
        return () => {};
    }, []);

    return (
        <>
            <CardBox title={"Symbol Asset Type Listing"}>
                <FlexiDataTable
                    rowKeyProperty="symbolAssetTypeId"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
        </>
    );
};

export default SymbolAssetTypeList;
