import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY } from "@/constants";
import { CustomPaginationProps, FlexiDataTableCallbackProps } from "@/constants/type";
import SoundButton from "@/pages/SystemMonitor/components/SoundButton";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps } from "@/utils/Common";
import { message } from "antd";
import { useEffect, useMemo, useState } from "react";
import syndicateTradingAudio from "@/assets/audios/rc/syndicate-trading-audio.mp3";
import { SyndicateTradingData } from "..";

const AllTable = () => {
    const [data, setData] = useState<SyndicateTradingData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);

    const columns: any[] = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
            fixed: "left",
            sorter: (a: any, b: any) => a.server.localeCompare(b.server),
        }),
        DTColProps.XSmall({
            title: "Batch Number",
            dataIndex: "batchNum",
            key: "batchNum",
            sorter: (a: any, b: any) => a.batchNum - b.batchNum,
        }),
        DTColProps.XSmall({
            title: "Ticket",
            dataIndex: "ticket",
            key: "ticket",
            sorter: (a: any, b: any) => a.ticket - b.ticket,
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
            sorter: (a: any, b: any) => a.login - b.login,
        }),
        DTColProps.Small({
            title: "Group",
            dataIndex: "group",
            key: "group",
            sorter: (a: any, b: any) => a.group.localeCompare(b.group),
        }),
        DTColProps.Small({
            title: "Open Time",
            dataIndex: "openTimeStr",
            key: "openTimeStr",
            sorter: (a: any, b: any) => a.openTimeStr.localeCompare(b.openTimeStr),
        }),
        DTColProps.XXSmall({
            title: "Side",
            dataIndex: "cmd",
            key: "cmd",
            sorter: (a: any, b: any) => a.cmd.localeCompare(b.cmd),
            render: (cmd: number) => cmd === 0 ? "Buy"
                : cmd === 1 ? "Sell"
                    : cmd,
        }),
        DTColProps.XSmall({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            sorter: (a: any, b: any) => a.symbol.localeCompare(b.symbol),
        }),
        DTColProps.XSmall({
            title: "Lots",
            dataIndex: "volume",
            key: "volume",
            sorter: (a: any, b: any) => a.volume - b.volume,
        }),
        DTColProps.XSmall({
            title: "Batch Lots",
            dataIndex: "sumVolume",
            key: "sumVolume",
            sorter: (a: any, b: any) => a.sumVolume - b.sumVolume,
        }),
        DTColProps.XSmall({
            title: "Open Price",
            dataIndex: "openPrice",
            key: "openPrice",
            sorter: (a: any, b: any) => a.openPrice - b.openPrice,
            render: (openPrice: string) => parseFloat(openPrice).toFixed(2),
        }),
        DTColProps.XSmall({
            title: "Exposure",
            dataIndex: "exposure",
            key: "exposure",
            sorter: (a: any, b: any) => a.exposure - b.exposure,
            render: (exposure: string) => parseFloat(exposure).toFixed(2),
        }),
        DTColProps.Small({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
            sorter: (a: any, b: any) => a.comment.localeCompare(b.comment),
        }),
        DTColProps.Small({
            title: "Change Reason",
            dataIndex: "changeReason",
            key: "changeReason",
            sorter: (a: any, b: any) => a.changeReason.localeCompare(b.changeReason),
        }),
    ];

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                if (FormData.pagination.current !== pagination.current || FormData.pagination.pageSize !== pagination.pageSize) {
                    setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                    setRunRefetchDataList(true);
                };
                break;
            default:
                break;
        };
    };

    const callVoiceUrl = (voiceNum: any, voiceSymbol: any) => {
        /**
         *  16/08/2024 - Xin
         *  This function is used to get the voice url from the backend and play said audio
         *  but doesn't seem to be used in RC as well
         *  - it is set up but not used
         */
        const formData = new FormData();
        formData.append("voiceNum", voiceNum.toString());
        formData.append("voiceSymbol", voiceSymbol.toString());
        plainAxiosInstance
            .post(`${APIs.RC_SYNDICATE_TRADING.POST_GET_VOICE_URL}`, formData)
            .then(res => {
                const aud = new Audio(res.data);
                aud.play();
            })
            .catch(err => {
                message.error(`Failed to get voice url: ${err}`, 3);
            });
    };

    const getAllData = () => {
        let current = pagination.current === undefined ? 1 : pagination.current;
        let pageSize = pagination.pageSize === undefined ? 10 : pagination.pageSize;

        setIsLoading(true);
        plainAxiosInstance
            .post(`${APIs.RC_SYNDICATE_TRADING.POST_TOGETHER_ALL_DATA}`, {
                limit: pageSize,
                offset: (current - 1) * pageSize,
            })
            .then(res => {
                const data: any = res.data;
                if (res.status === 200) {
                    setData(data.rows);
                    setPagination((prev) => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination((prev) => ({ ...prev, total: 0 }));
                };
            })
            .catch(err => {
                message.error(`Failed to get all data: ${err}`, 3);
                setData([]);
                setPagination((prev) => ({ ...prev, total: 0 }));
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getAllData();
            setRunRefetchDataList(false);
        };
        return () => { };
    }, [runRefetchDataList]);

    const playAudio: boolean = useMemo(() => {
        let voiceNum: any[] = [], voiceSymbol: any[] = [];
        let result = data.some((item: any) => {
            if (item.isNewData === 1) {
                if (item.sumVolume >= 50) {
                    voiceNum.push(item.sumVolume);
                    voiceSymbol.push(item.symbol);
                }
                return true;
            }
            if (item.exposure >= 50 * 100000) {
                return true;
            }
            return false;
        });
        if (voiceNum.length > 0) {
            callVoiceUrl(voiceNum, voiceSymbol);
        };
        return result;
    }, [data]);

    useEffect(() => {
        getAllData();

        const timer = setInterval(() => {
            getAllData();
        }, 30000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div className="syndicate-trading-collapse-item-div">
            <FlexiDataTable
                bordered
                rowKeyProperty="batchNum"
                title={""}
                columns={columns}
                options={{
                    enableFilter: false,
                    showHideColumns: false,
                    recordRowClassName: (record: any) => {
                        return record.isInMinute === 1 ? "row-yellow" : "";
                    },
                    extraButtons: () => (
                        <div key={"alarm-btn"}>
                            <SoundButton soundSrc={syndicateTradingAudio} isActive={playAudio} />
                        </div>
                    )
                }}
                dataSource={data ?? []}
                callback={componentCallback}
                loading={isLoading}
                pagination={pagination}
                serverSide={true}
            />
        </div>
    );
};

export default AllTable;