import BasicFilter from "@/components/BasicFilter";
import {
    IRCPriceAlarmSettingDetails,
    useRCPriceAlarmSettingDetails,
    useRCPriceAlarmSettingMutation,
    useRCPriceAlarmSettingServers,
} from "@/hooks/useRCPriceAlarmSettings";
import { DeleteOutlined, DownloadOutlined, InboxOutlined, PlusOutlined, SaveOutlined, UndoOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Select, Space, Typography, Upload, UploadFile, message, notification } from "antd";
import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { matchSorter } from "match-sorter";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import MessageCard from "@/pages/SystemMonitor/components/MessageCard";
import { CellValueChangedEvent, ColDef, GetRowIdParams, GridApi, GridReadyEvent, RowClassParams, RowNode, RowStyle } from "ag-grid-community";
import { colorMap } from "@/pages/SystemMonitor/StatusMonitor/statusUtils";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";

interface ExtendedRCPriceAlarmSettingDetails extends IRCPriceAlarmSettingDetails {
    isModified?: boolean;
    isNewlyAdded?: boolean;
}

const PriceAlarmSetting = () => {
    const [serverType, setServerType] = useState<string>("all");
    const [server, setServer] = useState<string | null>(null);
    const [currentData, setCurrentData] = useState<IRCPriceAlarmSettingDetails[]>([]);
    const [openAddNewModal, setOpenAddNewModal] = useState(false);
    const agGridRef = useRef<AgGridReact>(null);
    const gridApiRef = useRef<GridApi | null>(null);
    const isEditingRef = useRef(false);

    const handleOpenAddNewModal = (open: boolean) => {
        setOpenAddNewModal(open);
    };

    const { rcPriceAlarmSettingServers, isLoading: isLoadingServers } = useRCPriceAlarmSettingServers();
    const {
        rcPriceAlarmSettingDetails,
        refetchRcPriceAlarmSettingDetails,
        isLoading: isLoadingDetails,
    } = useRCPriceAlarmSettingDetails({
        server: server === null ? null : `${server}${serverType === "new" ? "?isNew=1" : serverType === "old" ? "?isNew=0" : ""}`,
    });
    const { updatePriceAlarmSetting, isLoadingUpdatePriceAlarmSetting, isLoadingDeletePriceAlarmSetting, deletePriceAlarmSetting } =
        useRCPriceAlarmSettingMutation({
            server: server === null ? null : `${server}${serverType === "new" ? "?isNew=1" : serverType === "old" ? "?isNew=0" : ""}`,
        });

    // apply currentData from rcPriceAlarmSettingDetails if server changes and rcPriceAlarmSettingDetails is loaded and not loading
    useEffect(() => {
        if (rcPriceAlarmSettingDetails && !isLoadingDetails && server !== String(currentData?.[0]?.serverUno)) {
            setCurrentData(JSON.parse(JSON.stringify(rcPriceAlarmSettingDetails)));
        }
    }, [rcPriceAlarmSettingDetails, isLoadingDetails, server, currentData]);

    const getRowId = useCallback((params: GetRowIdParams<ExtendedRCPriceAlarmSettingDetails>) => {
        return `${params.data.symbol}-${params.data.cleanSymbol}-${params.data.serverUno}`;
    }, []);

    const onCellValueChanged = useCallback((params: CellValueChangedEvent) => {
        const updatedData = { ...params.data, isModified: true };
        params.node.setData(updatedData);
    }, []);

    const handleDelete = useCallback(
        (data: ExtendedRCPriceAlarmSettingDetails) => {
            if (gridApiRef.current) {
                // @ts-ignore
                const rowId = getRowId({ data });
                console.log("Attempting to delete node with ID:", rowId);
                console.log("Data object:", data);

                const node = gridApiRef.current.getRowNode(rowId);
                if (node) {
                    const updatedData = { ...node.data, isDeleted: true, isModified: true };
                    node.setData(updatedData);
                    gridApiRef.current.refreshCells({ rowNodes: [node], force: true });
                    console.log("Row marked as deleted:", rowId);
                } else {
                    console.error("Node not found for ID:", rowId);
                    // Log all available row IDs for debugging
                    const allRowIds: string[] = [];
                    gridApiRef.current.forEachNode(node => {
                        // @ts-ignore
                        allRowIds.push(getRowId({ data: node.data }));
                    });
                    console.log("All available row IDs:", allRowIds);
                }
            }
        },
        [getRowId]
    );

    const handleRevert = useCallback(
        (data: IRCPriceAlarmSettingDetails) => {
            if (gridApiRef.current) {
                const updatedRow = { ...data, isDeleted: false, isModified: false };
                gridApiRef.current.applyTransaction({ update: [updatedRow] });
            }
        },
        [gridApiRef]
    );

    const handleReset = useCallback(() => {
        if (gridApiRef.current) {
            // Remove all newly added rows
            const rowsToRemove: any[] = [];
            gridApiRef.current.forEachNode(node => {
                if (node.data.isNewlyAdded) {
                    rowsToRemove.push(node.data);
                }
            });

            if (rowsToRemove.length > 0) {
                gridApiRef.current.applyTransaction({ remove: rowsToRemove });
            }

            // Reset existing rows to their original state
            gridApiRef.current.forEachNode(node => {
                if (node.data.isModified) {
                    const resetData = { ...node.data, isModified: false, isDeleted: false };
                    delete resetData.isNewlyAdded;
                    node.setData(resetData);
                }
            });

            // Refresh the grid
            gridApiRef.current.refreshCells({ force: true });

            // Refetch the original data from the server
            refetchRcPriceAlarmSettingDetails().then(() => {
                setCurrentData(JSON.parse(JSON.stringify(rcPriceAlarmSettingDetails)));
            });
            // setCurrentData(rcPriceAlarmSettingDetails); with stringify and parse

            setValidateError([]);

            // setCurrentData(rcPriceAlarmSettingDetails);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchRcPriceAlarmSettingDetails]);

    // show which row has what error, and able to click on the row and jump to the error
    /*
    validateError pattern would be like
    [
        {
            symbol: "AUDUSD",
            error: ["Clean symbol not found", "Monday time is invalid"]
        }
    ]
    */
    interface IValidateError {
        symbol: string;
        error: string[];
    }
    const [validateError, setValidateError] = useState<IValidateError[]>([]);
    const handleValidateBeforeSubmit = useCallback(() => {
        return new Promise<boolean>(resolve => {
            const errors: IValidateError[] = [];

            // Helper function to validate and combine time ranges
            const validateTimeRanges = (timeString: string): string | null => {
                // Remove all spaces from the input
                timeString = timeString.replace(/\s/g, "");

                // Split the string into individual ranges
                const ranges = timeString.split(";");

                // Validate each range
                const validRanges = ranges.filter(range => {
                    const [start, end] = range.split("-");
                    return /^([01]\d|2[0-3]|24):([0-5]\d)$/.test(start) && /^([01]\d|2[0-3]|24):([0-5]\d)$/.test(end);
                });

                if (validRanges.length !== ranges.length) {
                    return null; // Invalid format
                }

                // Convert ranges to minutes, sort, and combine
                let timeRanges = validRanges
                    .map(range => {
                        const [start, end] = range.split("-").map(timeToMinutes);
                        return { start, end };
                    })
                    .sort((a, b) => a.start - b.start);

                let combinedRanges = [timeRanges[0]];
                for (let i = 1; i < timeRanges.length; i++) {
                    let lastRange = combinedRanges[combinedRanges.length - 1];
                    if (timeRanges[i].start <= lastRange.end) {
                        lastRange.end = Math.max(lastRange.end, timeRanges[i].end);
                    } else {
                        combinedRanges.push(timeRanges[i]);
                    }
                }

                // Convert back to string format
                return combinedRanges.map(range => `${minutesToTime(range.start)}-${minutesToTime(range.end)}`).join(";");
            };

            if (gridApiRef.current) {
                gridApiRef.current.forEachNode(node => {
                    const errorMessages: string[] = [];
                    const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

                    days.forEach(day => {
                        const validatedTime = validateTimeRanges(node.data[day]);
                        if (validatedTime === null) {
                            errorMessages.push(`${day.charAt(0).toUpperCase() + day.slice(1)} time is invalid: ${node.data[day]}`);
                        } else {
                            // Update the node data with the combined time ranges
                            node.setDataValue(day, validatedTime);
                        }
                    });

                    if (errorMessages.length > 0) {
                        errors.push({ symbol: node.data.symbol, error: errorMessages });
                    }
                });
            }

            console.log("Errors found:", errors);
            setValidateError(errors);
            resolve(errors.length === 0);
        });
    }, []);

    // Helper functions
    function timeToMinutes(time: string): number {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    }

    function minutesToTime(minutes: number): string {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
    }

    const handleSubmit = useCallback(async () => {
        const isValid = await handleValidateBeforeSubmit();
        if (!isValid) {
            message.error("Please correct the errors before submitting.");
            return;
        }
        if (gridApiRef.current) {
            const updatedData: IRCPriceAlarmSettingDetails[] = [];
            const deletedData: IRCPriceAlarmSettingDetails[] = [];
            gridApiRef.current.forEachNode(node => {
                if (node.data.isModified) {
                    if (node.data.isDeleted) {
                        deletedData.push(node.data);
                    } else {
                        updatedData.push(node.data);
                    }
                }
            });
            console.log("Modified data to be submitted:", updatedData);
            console.log("Deleted data to be submitted:", deletedData);

            // if no data to submit, return
            if (updatedData.length === 0 && deletedData.length === 0) {
                message.info("No data to submit");
                return;
            }
            try {
                // for update, use updatePriceAlarmSetting
                // for delete, use deletePriceAlarmSetting
                // do promise all for both
                const resp = await Promise.all([
                    // if there's no update, dont include updatePriceAlarmSetting
                    // if there's no deletion, dont include deletePriceAlarmSetting
                    updatedData.length === 0 ? Promise.resolve() : updatePriceAlarmSetting(updatedData),
                    deletedData.length === 0 ? Promise.resolve() : deletePriceAlarmSetting(deletedData),
                ]);
                // const resp = await Promise.all([updatePriceAlarmSetting(updatedData), deletePriceAlarmSetting(deletedData)]);
                console.log("Response from submitting:", resp);
                console.log("response for updatePriceAlarmSetting:", resp[0]);
                console.log("response for deletePriceAlarmSetting:", resp[1]);
                if ((updatedData.length === 0 || resp[0]) && (deletedData.length === 0 || resp[1])) {
                    message.success("Data saved successfully");
                    // reset all modified flags
                    handleReset();
                }
            } catch (error) {
                console.error("Error on submitting data:", error);
                message.error("Error on submitting data");
                // @ts-ignore
                message.error(error?.response?.data?.message || "Unknown error");
            }

            // Call your API functions here
            // updatePriceAlarmSetting(updatedData);
            // deletePriceAlarmSetting(deletedData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleValidateBeforeSubmit]);

    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const handleDownload = useCallback(
        async ({ downloadAll = false }: { downloadAll: boolean }) => {
            setDownloading(true);
            plainAxiosInstance
                .get(`${APIs.RC_PRICE_SETTINGS.DOWNLOAD_PRICE_ALARM_SETTINGS}` + (downloadAll ? "" : `/${server}`), {
                    headers: {
                        Accept: "application/octet-stream, application/zip, */*",
                    },
                    responseType: "blob",
                })
                .then(response => {
                    // Check if the response content type is 'application/octet-stream'
                    console.log(response);

                    const contentType = response.headers["content-type"];
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "application/zip" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        // Handle the file download response
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `price_alarm_setting_${server}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                        notification.success({
                            message: "Downloaded",
                            description: `Downloaded successfully`,
                        });
                    } else {
                        notification.error({
                            message: "Error",
                            description: `Received non-file response. Error: ${response}`,
                        });
                        console.log("Received non-file response:", response);
                    }
                })
                .catch((err: any) => {
                    notification.error({
                        message: "Error",
                        description: `Download error: ${err}`,
                    });
                    console.log("download error", err);
                })
                .finally(() => {
                    setDownloading(false);
                });
        },
        [server]
    );

    const getRowStyle = useCallback((params: RowClassParams<ExtendedRCPriceAlarmSettingDetails>): RowStyle => {
        //@ts-ignore
        if (params?.data?.isDeleted) {
            return { background: "#ffcccb", color: "#888" };
        }
        //@ts-ignore
        if (params?.data?.isModified) {
            return { background: "lightyellow" };
        }
        return {};
    }, []);

    const getRowClass = useCallback((params: RowClassParams) => {
        if (params.data.isDeleted) return "deleted-row";
        if (params.data.isModified) return "modified-row";
        return "";
    }, []);

    // get columnDefs from rcPriceAlarmSettingDetails
    // server, symbol, clean symbol, monday, tuesday, wednesday, thursday, friday, saturday, sunday, no price time, open no price, symbol type, digit, show, open sound, from rc
    const columnDefs: ColDef<ExtendedRCPriceAlarmSettingDetails>[] = [
        // {
        //     headerName: "Server",
        //     field: "serverName",
        //     sortable: true,
        // },
        {
            headerName: "Symbol",
            field: "symbol",
            sortable: true,
            // make this column sticky to left when scrolling table to right side
            pinned: "left",
            cellStyle: { backgroundColor: "#f0f0f0" },
            // make it able to filter
            filter: true,

            // editable: true,
            // cellEditor: "agTextCellEditor",
            // cellEditorParams: {
            //     maxLength: "100",
            // },
        },
        {
            headerName: "Clean Symbol",
            field: "cleanSymbol",
            sortable: true,
            editable: true,
            cellEditor: "agTextCellEditor",
            cellEditorParams: {
                maxLength: "100",
            },
        },
        {
            headerName: "Monday",
            field: "monday",
            sortable: true,
            editable: true,
            cellEditor: "agTextCellEditor",
            cellEditorParams: {
                maxLength: "100",
            },
        },
        {
            headerName: "Tuesday",
            field: "tuesday",
            sortable: true,
            editable: true,
            cellEditor: "agTextCellEditor",
            cellEditorParams: {
                maxLength: "100",
            },
        },
        {
            headerName: "Wednesday",
            field: "wednesday",
            sortable: true,
            editable: true,
            cellEditor: "agTextCellEditor",
            cellEditorParams: {
                maxLength: "100",
            },
        },
        {
            headerName: "Thursday",
            field: "thursday",
            sortable: true,
            editable: true,
            cellEditor: "agTextCellEditor",
            cellEditorParams: {
                maxLength: "100",
            },
        },
        {
            headerName: "Friday",
            field: "friday",
            sortable: true,
            editable: true,
            cellEditor: "agTextCellEditor",
            cellEditorParams: {
                maxLength: "100",
            },
        },
        {
            headerName: "Saturday",
            field: "saturday",
            sortable: true,
            editable: true,
            cellEditor: "agTextCellEditor",
            cellEditorParams: {
                maxLength: "100",
            },
        },
        {
            headerName: "Sunday",
            field: "sunday",
            sortable: true,
            editable: true,
            cellEditor: "agTextCellEditor",
            cellEditorParams: {
                maxLength: "100",
            },
        },
        {
            headerName: "No Price Time",
            field: "noPriceTime",
            sortable: true,
            editable: true,
            cellEditor: "agTextCellEditor",
            cellEditorParams: {
                maxLength: "100",
            },
        },
        {
            headerName: "Open No Price",
            field: "openNoPrice",
            sortable: true,
            editable: true,
            cellEditor: "agTextCellEditor",
            cellEditorParams: {
                maxLength: "100",
            },
        },
        {
            headerName: "Symbol Type",
            field: "typeName",
            sortable: true,
            cellStyle: { backgroundColor: "#f0f0f0" },
        },
        {
            headerName: "Digit",
            field: "digit",
            sortable: true,
            editable: true,
            cellEditor: "agTextCellEditor",
            cellEditorParams: {
                maxLength: "100",
            },
        },
        {
            headerName: "Show",
            field: "showStatus",
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: [1, 0], // Only the values
                formatValue: (value: any) => {
                    const item = [
                        { value: 1, label: "Show" },
                        { value: 0, label: "Hide" },
                    ].find(item => item.value === value);
                    return item ? item.label : value;
                },
            },
            valueFormatter: (params: any) => {
                const item = [
                    { value: 1, label: "Show" },
                    { value: 0, label: "Hide" },
                ].find(item => item.value === params.value);
                return item ? item.label : params.value;
            },
            cellRenderer: (params: any) => {
                const item = [
                    { value: 1, label: "Show" },
                    { value: 0, label: "Hide" },
                ].find(item => item.value === params.value);
                return item ? item.label : params.value;
            },
        },
        {
            headerName: "Open Sound",
            field: "alertStatus",
            sortable: true,
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: [1, 0], // Only the values
                formatValue: (value: any) => {
                    const item = [
                        { value: 1, label: "Open Sound" },
                        { value: 0, label: "Mute" },
                    ].find(item => item.value === value);
                    return item ? item.label : value;
                },
            },
            valueFormatter: (params: any) => {
                const item = [
                    { value: 1, label: "Open Sound" },
                    { value: 0, label: "Mute" },
                ].find(item => item.value === params.value);
                return item ? item.label : params.value;
            },
            cellRenderer: (params: any) => {
                const item = [
                    { value: 1, label: "Open Sound" },
                    { value: 0, label: "Mute" },
                ].find(item => item.value === params.value);
                return item ? item.label : params.value;
            },
        },
        {
            headerName: "From RC",
            field: "forceSession",
            sortable: true,
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: [1, 0], // Only the values
                formatValue: (value: any) => {
                    const item = [
                        { value: 1, label: "True" },
                        { value: 0, label: "False" },
                    ].find(item => item.value === value);
                    return item ? item.label : value;
                },
            },
            valueFormatter: (params: any) => {
                const item = [
                    { value: 1, label: "True" },
                    { value: 0, label: "False" },
                ].find(item => item.value === params.value);
                return item ? item.label : params.value;
            },
            cellRenderer: (params: any) => {
                const item = [
                    { value: 1, label: "True" },
                    { value: 0, label: "False" },
                ].find(item => item.value === params.value);
                return item ? item.label : params.value;
            },
        },
        // check if is modified
        {
            headerName: "Modified Status",
            field: "isModified",
            sortable: true,
            cellRenderer: (params: any) => {
                return params.value ? "Yes" : "No";
            },
        },
        // handle delete row
        {
            headerName: "Action",
            // field: "id",
            width: 110,
            pinned: "right",
            cellRenderer: (params: any) => {
                if (params.data.isDeleted) {
                    return (
                        <Button icon={<UndoOutlined />} onClick={() => handleRevert(params.data)} size="small">
                            Revert
                        </Button>
                    );
                }
                return (
                    <Button danger icon={<DeleteOutlined />} type="text" onClick={() => handleDelete(params.data)} size="small">
                        Delete
                    </Button>
                );
            },
        },
    ];

    const { Text } = Typography;

    const getCurrentSymbols = useCallback(() => {
        if (gridApiRef.current) {
            const symbols: string[] = [];
            gridApiRef.current.forEachNode(node => {
                if (node.data && node.data.symbol) {
                    symbols.push(node.data.symbol.trim().toLowerCase());
                }
            });
            return symbols;
        }
        return [];
    }, []);
    return (
        <>
            <Space direction="vertical" style={{ width: "100%" }}>
                <BasicFilter
                    titleBarChildren={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                flexWrap: "wrap",
                                gap: "4px",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                type="ghost"
                                icon={<UploadOutlined />}
                                disabled={server === null}
                                style={{ display: "flex", gap: 0, alignItems: "center" }}
                                onClick={() => setOpenUploadModal(true)}
                            >
                                <span>Upload</span>
                            </Button>
                            <Button
                                type="ghost"
                                icon={<DownloadOutlined />}
                                disabled={server === null}
                                loading={downloading}
                                style={{ display: "flex", gap: 0, alignItems: "center" }}
                                onClick={() => handleDownload({ downloadAll: false })}
                            >
                                <span>Download</span>
                            </Button>
                            <Button
                                type="ghost"
                                icon={<DownloadOutlined />}
                                disabled={server === null}
                                loading={downloading}
                                style={{ display: "flex", gap: 0, alignItems: "center" }}
                                onClick={() => handleDownload({ downloadAll: true })}
                            >
                                <span>Download All</span>
                            </Button>
                        </div>
                    }
                >
                    <Row gutter={[8, 8]}>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <Text strong>Server Type</Text>
                                <Select
                                    placeholder="Server Type"
                                    style={{ width: "100%" }}
                                    options={[
                                        { label: "All", value: "all" },
                                        { label: "New", value: "new" },
                                        { label: "Old", value: "old" },
                                    ]}
                                    value={serverType}
                                    onChange={value => {
                                        setServerType(value);
                                        setValidateError([]);
                                    }}
                                />
                            </Space>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <Text strong>Server</Text>
                                <Select
                                    showSearch
                                    //make search search with label, instead of the serverUno
                                    optionFilterProp="label"
                                    placeholder="Server"
                                    style={{ width: "100%" }}
                                    disabled={isLoadingServers}
                                    options={rcPriceAlarmSettingServers
                                        .map(server => {
                                            return { label: server.name, value: server.serverUno.toString(), order: server.order };
                                        })
                                        // sort by each item's order key if available, if order is null, put to the last
                                        .sort((a, b) => {
                                            if (a.order && b.order) {
                                                return a.order - b.order;
                                            }
                                            if (a.order) {
                                                return -1;
                                            }
                                            if (b.order) {
                                                return 1;
                                            }
                                            return 0;
                                        })}
                                    value={server}
                                    onChange={value => {
                                        setServer(value);
                                        setValidateError([]);
                                    }}
                                />
                            </Space>
                        </Col>
                    </Row>
                </BasicFilter>
                {/* show current filtered servers */}
                {!server ? (
                    <MessageCard type="info">
                        <Text>Select Server First</Text>
                    </MessageCard>
                ) : isLoadingDetails ? (
                    <MessageCard type="info">
                        <Text>Loading...</Text>
                    </MessageCard>
                ) : (
                    <Space direction="vertical" style={{ width: "100%" }}>
                        {server && (
                            <Space wrap>
                                <MessageCard type="info">
                                    <Text>
                                        Server: {rcPriceAlarmSettingServers.find(serverItem => serverItem?.serverUno.toString() === server)?.name}
                                    </Text>
                                </MessageCard>
                                {serverType !== "all" && <Text>Server Type: {serverType}</Text>}
                            </Space>
                        )}
                        <div style={{ display: "flex", alignItems: "center", gap: 4, flexWrap: "wrap" }}>
                            {/* <Input
                            style={{ flex: 1, minWidth: 200 }}
                            placeholder="Search"
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                        /> */}
                            {/* submit button */}
                            <Button type="ghost" onClick={handleReset}>
                                Reset Changes
                            </Button>
                            <Button
                                icon={<PlusOutlined />}
                                type="ghost"
                                onClick={() => {
                                    handleOpenAddNewModal(true);
                                }}
                            >
                                Add
                            </Button>
                            <Button
                                icon={<SaveOutlined />}
                                type="primary"
                                disabled={isLoadingUpdatePriceAlarmSetting || isLoadingDeletePriceAlarmSetting}
                                onClick={() => {
                                    if (isEditingRef.current === true && gridApiRef.current) {
                                        gridApiRef.current.stopEditing();
                                    }
                                    setTimeout(() => {
                                        handleSubmit();
                                    }, 0);
                                }}
                                loading={isLoadingUpdatePriceAlarmSetting || isLoadingDeletePriceAlarmSetting}
                            >
                                {/* <Button icon={<SaveOutlined />} type="primary" onClick={handleSubmit}> */}
                                Save
                            </Button>
                        </div>
                        {validateError?.length >= 1 && (
                            <Space direction="vertical" style={{ width: "100%", border: "1px solid #f0f0f0", padding: "1rem" }}>
                                <Text strong type="danger">
                                    Validation Errors
                                </Text>
                                {validateError.map((error, index) => (
                                    <Space key={index} direction="vertical" style={{ width: "100%" }} size={1}>
                                        <Text strong>{error.symbol}</Text>
                                        <Space direction="vertical" size={0}>
                                            {error.error.map((message, index) => (
                                                <Text key={index} type="danger">
                                                    {message}
                                                </Text>
                                            ))}
                                        </Space>
                                    </Space>
                                ))}
                            </Space>
                        )}

                        <div className="ag-theme-balham" style={{ height: 600, width: "100%" }}>
                            <AgGridReact
                                ref={agGridRef}
                                rowData={currentData}
                                columnDefs={columnDefs}
                                onGridReady={(params: GridReadyEvent) => {
                                    gridApiRef.current = params.api;
                                    // params.api.sizeColumnsToFit();
                                }}
                                onCellValueChanged={onCellValueChanged}
                                getRowId={getRowId}
                                getRowClass={getRowClass}
                                getRowStyle={getRowStyle}
                                onCellEditingStarted={() => {
                                    isEditingRef.current = true;
                                }}
                                onCellEditingStopped={() => {
                                    isEditingRef.current = false;
                                }}
                                rowSelection="multiple"
                                suppressRowClickSelection={true}
                                enableCellTextSelection={true}
                                suppressCopyRowsToClipboard={true}
                                animateRows={true}
                                loading={isLoadingDetails && server !== null}
                            />
                        </div>
                        {/* display changes in table */}
                    </Space>
                )}
            </Space>
            <ModalNewPriceAlarmSetting
                open={openAddNewModal}
                onClose={() => setOpenAddNewModal(false)}
                callback={(values: any) => {
                    if (gridApiRef.current) {
                        let existingNode: any = null;
                        gridApiRef.current.forEachNode(node => {
                            if (node.data && node.data.symbol && node.data.symbol.toLowerCase().trim() === values.symbol.toLowerCase().trim()) {
                                existingNode = node;
                            }
                        });

                        if (existingNode) {
                            // Update existing row
                            const updatedRow = { ...existingNode.data, ...values, isModified: true };
                            gridApiRef.current.applyTransaction({ update: [updatedRow] });
                            message.info("Updated existing symbol");
                        } else {
                            // Add new row
                            gridApiRef.current.applyTransaction({
                                add: [
                                    {
                                        ...values,
                                        isModified: true,
                                        isNewlyAdded: true,
                                        typeName: "Unknown",
                                        serverUno: server,
                                        serverName: rcPriceAlarmSettingServers?.find(serverItem => serverItem?.serverUno.toString() === server)?.name,
                                    },
                                ],
                            });
                            message.success("Added new symbol");
                        }
                    }
                }}
                // symbols={rcPriceAlarmSettingDetails.map(item => item.symbol.trim().toLowerCase())}
                symbols={getCurrentSymbols()}
            />
            <ModalUploadPriceSettings
                open={openUploadModal}
                onClose={() => setOpenUploadModal(false)}
                callback={() => {
                    refetchRcPriceAlarmSettingDetails().then(() => {
                        setCurrentData(JSON.parse(JSON.stringify(rcPriceAlarmSettingDetails)));
                    });
                    // setCurrentData(rcPriceAlarmSettingDetails);
                    setValidateError([]);
                }}
            />
        </>
    );
};

const ModalNewPriceAlarmSetting = ({
    open,
    onClose,
    callback,
    symbols,
}: {
    open: boolean;
    onClose: () => void;
    callback: (values: any) => void;
    symbols: string[];
}) => {
    const [form] = Form.useForm();
    const { Text, Title } = Typography;
    const { Item } = Form;

    const initFormValue = {
        symbol: "",
        cleanSymbol: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
        sunday: "",
        noPriceTime: "",
        openNoPrice: "",
        digit: "",
        showStatus: null,
        alertStatus: null,
        forceSession: null,
    };

    const handleSubmit = async (values: any) => {
        // console.log("Form values:", values);

        callback(values);
        // reset fields
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            open={open}
            title="New Price Alarm"
            onCancel={() => {
                // reset fields
                form.resetFields();
                onClose();
            }}
            footer={null}
            width={800}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={initFormValue}
                /* onValuesChange={handleFormUpdate} */ requiredMark={true}
                onFinish={handleSubmit}
            >
                <Space style={{ width: "100%" }} direction="vertical" wrap size={24}>
                    <Space style={{ width: "100%" }} direction="vertical" wrap size={0}>
                        <Title level={5}>General Settings</Title>
                        <Row gutter={[8, 8]}>
                            <NormalInputField
                                label="Symbol"
                                name="symbol"
                                symbols={symbols}
                                // value={form.getFieldValue("symbol")}
                                // onChange={value => form.setFieldsValue({ symbol: value })}
                            />
                            <NormalInputField
                                label="Clean Symbol"
                                name="cleanSymbol"
                                // value={form.getFieldValue("cleanSymbol")}
                                // onChange={value => form.setFieldsValue({ cleanSymbol: value })}
                            />
                        </Row>
                    </Space>
                    <Space style={{ width: "100%" }} direction="vertical" wrap size={0}>
                        <Title level={5}>Time Settings</Title>
                        <Row gutter={[8, 8]}>
                            <TimeRangeInputField label="Monday" name="monday" />
                            <TimeRangeInputField label="Tuesday" name="tuesday" />
                            <TimeRangeInputField label="Wednesday" name="wednesday" />
                            <TimeRangeInputField label="Thursday" name="thursday" />
                            <TimeRangeInputField label="Friday" name="friday" />
                            <TimeRangeInputField label="Saturday" name="saturday" />
                            <TimeRangeInputField label="Sunday" name="sunday" />
                        </Row>
                    </Space>
                    <Space style={{ width: "100%" }} direction="vertical" wrap size={0}>
                        <Title level={5}>Alarm Trigger Time and Digits</Title>
                        <Row gutter={[8, 8]}>
                            {/* no price time, open no price, digit */}
                            <NormalInputField
                                label="No Price Time"
                                name="noPriceTime"
                                // value={form.getFieldValue("noPriceTime")}
                                // onChange={value => form.setFieldsValue({ noPriceTime: value })}
                            />
                            <NormalInputField
                                label="Open No Price"
                                name="openNoPrice"
                                // value={form.getFieldValue("openNoPrice")}
                                // onChange={value => form.setFieldsValue({ openNoPrice: value })}
                            />
                            <NormalInputField
                                label="Digit"
                                name="digit"
                                // value={form.getFieldValue("digit")}
                                // onChange={value => form.setFieldsValue({ digit: value })}
                            />
                        </Row>
                    </Space>
                    <Space style={{ width: "100%" }} direction="vertical" wrap size={0}>
                        <Title level={5}>Other Details</Title>
                        <Row gutter={[8, 8]}>
                            {/* show, open sound, from rc */}
                            <DropdownInputField
                                label="Show"
                                name="showStatus"
                                options={[
                                    { label: "Show", value: 1 },
                                    { label: "Hide", value: 0 },
                                ]}
                            />
                            <DropdownInputField
                                label="Open Sound"
                                name="alertStatus"
                                options={[
                                    { label: "Open Sound", value: 1 },
                                    { label: "Mute", value: 0 },
                                ]}
                            />
                            <DropdownInputField
                                label="From RC"
                                name="forceSession"
                                options={[
                                    { label: "True", value: 1 },
                                    { label: "False", value: 0 },
                                ]}
                            />
                        </Row>
                    </Space>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 8 }}>
                        {/* reset */}
                        <Button type="ghost" onClick={() => form.resetFields()} style={{ marginRight: "auto" }}>
                            Reset
                        </Button>
                        <Button
                            type="ghost"
                            onClick={() => {
                                form.resetFields();
                                onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </div>
                </Space>
            </Form>
        </Modal>
    );
};

interface DropdownInputFieldProps {
    label: string;
    name: string;
    options: { label: string; value: string | number }[];
}

const DropdownInputField: React.FC<DropdownInputFieldProps> = ({ label, name, options }) => {
    const { Text } = Typography;
    return (
        <Col xs={24} sm={12} md={8} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
            <Form.Item label={<Text>{label}</Text>} name={name} rules={[{ required: true, message: "This field is required" }]}>
                <Select options={options} />
            </Form.Item>
        </Col>
    );
};

const NormalInputField = ({ label, name, symbols }: { label: string; name: string; symbols?: string[] }) => {
    const validateSymbol = (_: any, value: any) => {
        if (!value) {
            return Promise.resolve();
        }
        const symbol = value.toLowerCase().trim();
        if (symbols && symbols.includes(symbol)) {
            return Promise.reject("Symbol already exists");
        }
        return Promise.resolve();
    };

    return (
        <Col xs={24} sm={12} md={8} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
            <Form.Item label={label} name={name} rules={[{ required: true, message: "This field is required" }, { validator: validateSymbol }]}>
                <Input allowClear />
            </Form.Item>
        </Col>
    );
};

const TimeRangeInputField = ({ label, name }: { label: string; name: string }) => {
    const { Item } = Form;
    const { Text } = Typography;
    // const validateTimeRange = (_: any, value: any) => {
    //     if (!value) {
    //         return Promise.resolve();
    //     }

    //     const timeRangeRegex = /^([01]\d|2[0-3]):[0-5]\d-([01]\d|2[0-3]):[0-5]\d(;([01]\d|2[0-3]):[0-5]\d-([01]\d|2[0-3]):[0-5]\d)?$/;

    //     if (timeRangeRegex.test(value)) {
    //         return Promise.resolve();
    //     }
    //     return Promise.reject("Please enter a valid time range format (e.g., 03:00-15:45 or 03:00-15:45;16:30-21:20)");
    // };
    const validateTimeRange = (_: any, value: string) => {
        if (!value) {
            return Promise.resolve();
        }

        const combinedRanges = validateAndCombineTimeRanges(value);

        if (combinedRanges === null) {
            return Promise.reject("Please enter a valid time range format (e.g., 03:00-15:45 or 03:00-15:45;16:30-21:20)");
        }

        // If the ranges were combined, update the form field value
        if (combinedRanges !== value) {
            // You might need to adjust this part depending on your form library
            // For example, with Ant Design's Form:
            // form.setFieldsValue({ [name]: combinedRanges });
        }

        return Promise.resolve();
    };
    const validateAndCombineTimeRanges = (timeString: string): string | null => {
        // Remove all spaces from the input
        timeString = timeString.replace(/\s/g, "");

        // Split the string into individual ranges
        const ranges = timeString.split(";");

        // Validate each range
        const validRanges = ranges.filter(range => {
            const [start, end] = range.split("-");
            return /^([01]\d|2[0-3]|24):([0-5]\d)$/.test(start) && /^([01]\d|2[0-3]|24):([0-5]\d)$/.test(end);
        });

        if (validRanges.length !== ranges.length) {
            return null; // Invalid format
        }

        // Convert ranges to minutes, sort, and combine
        let timeRanges = validRanges
            .map(range => {
                const [start, end] = range.split("-").map(timeToMinutes);
                return { start, end };
            })
            .sort((a, b) => a.start - b.start);

        let combinedRanges = [timeRanges[0]];
        for (let i = 1; i < timeRanges.length; i++) {
            let lastRange = combinedRanges[combinedRanges.length - 1];
            if (timeRanges[i].start <= lastRange.end) {
                lastRange.end = Math.max(lastRange.end, timeRanges[i].end);
            } else {
                combinedRanges.push(timeRanges[i]);
            }
        }

        // Convert back to string format
        return combinedRanges.map(range => `${minutesToTime(range.start)}-${minutesToTime(range.end)}`).join(";");
    };

    const timeToMinutes = (time: string): number => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    };

    const minutesToTime = (minutes: number): string => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
    };

    return (
        <Col xs={24} sm={12} md={8} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
            <Form.Item label={label} name={name} rules={[{ required: true, message: "This field is required" }, { validator: validateTimeRange }]}>
                <Input allowClear />
            </Form.Item>
        </Col>
    );
};

const ModalUploadPriceSettings = ({ open, onClose, callback }: { open: boolean; onClose: () => void; callback: () => void }) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [file, setFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { Text } = Typography;
    const { Dragger } = Upload;

    const handleUpload = async () => {
        if (!file) {
            setError("Please select a file");
            return;
        }
        setLoading(true);
        const formData = new FormData();
        formData.append("file", file);

        try {
            const resp = await plainAxiosInstance.post(APIs.RC_PRICE_SETTINGS.UPLOAD_PRICE_ALARM_SETTINGS, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log("Response from upload:", resp);
            message.success("File uploaded successfully");
            callback();
            // reset everything
            setFileList([]);
            setFile(null);
            setError(null);

            onClose();
        } catch (error) {
            console.error("Error on uploading file:", error);
            message.error("Error on uploading file");
            // @ts-ignore
            message.error(error?.response?.data?.message || "Unknown error");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={open} title="Upload Price Alarm Setting" onCancel={onClose} footer={null} width={400}>
            <Space direction="vertical" style={{ width: "100%" }} size={8}>
                <Dragger
                    accept=".xlsx"
                    fileList={fileList}
                    beforeUpload={file => {
                        setFileList([
                            {
                                uid: "-1",
                                name: file.name,
                                status: "done",
                                size: file.size,
                                type: file.type,
                            },
                        ]);
                        setFile(file);
                        return false;
                    }}
                    onRemove={() => {
                        setFileList([]);
                        setFile(null);
                    }}
                    multiple={false}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">extension support: .xlsx</p>
                    <p className="ant-upload-hint">maximum file size: 5MB</p>
                </Dragger>
                <Text type="danger">{error}</Text>
                <Button type="primary" onClick={handleUpload} loading={loading}>
                    Upload
                </Button>
            </Space>
        </Modal>
    );
};

export default PriceAlarmSetting;
