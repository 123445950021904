import { initialize } from "@/services/api";
import { getRefreshToken, getToken } from "@/services/localStorage";
import { DefaultIfEmpty } from "@/utils/object";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { message } from "antd";
import axios from "axios";

class KeyClockHelper {
    private _keyClockDomain: string = process.env.REACT_APP_KEYCLOCK_DOMAIN_URL || "";
    private _realmsName: string = process.env.REACT_APP_KEYCLOCK_REALMS_NAME || "";
    private _clientId: string = process.env.REACT_APP_KEYCLOCK_CLIENT_ID || "";
    private _clientSecret: string = process.env.REACT_APP_KEYCLOCK_CLIENT_SECRET || "";
    private _webOrigin: string = `${
        window.location.origin.indexOf("localhost:3000") > -1 ? "https://insight-dev.risk-vantagefx.com" : window.location.origin
    }/ssoredirection`;

    private _axioInstance: any = undefined;

    constructor() {
        this._axioInstance = axios.create({
            baseURL: this._keyClockDomain,
            timeout: 3000,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });

        this._axioInstance.interceptors.response.use((res: any) => {
            if (res.status === 200) {
                return Promise.resolve(res.data);
            }
            return Promise.reject({
                status: res.status,
                message: res.statusText,
            });
        });
    }

    keyClockLoginUrl: string = `${this._keyClockDomain}/realms/${this._realmsName}/protocol/openid-connect/auth?response_type=code&client_id=${this._clientId}&kc_idp_hint=azuread`;

    keyClockResetPasswordUrl: string = `${this._keyClockDomain}/realms/${this._realmsName}/protocol/openid-connect/auth?client_id=${this._clientId}&redirect_uri=${this._webOrigin}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`;

    getAccessToken = (values: any, fn: () => void) => {
        if (values.hasOwnProperty("code")) {
            this._axioInstance
                .post(
                    `/realms/${this._realmsName}/protocol/openid-connect/token`,
                    {
                        grant_type: "authorization_code",
                        client_id: this._clientId,
                        client_secret: this._clientSecret,
                        code: values["code"],
                    },
                    {
                        responseType: "json",
                    }
                )
                .then((res: any) => {
                    if (res.hasOwnProperty("access_token") && res.hasOwnProperty("refresh_token")) {
                        initialize(DefaultIfEmpty(res, "access_token", ""), DefaultIfEmpty(res, "refresh_token", ""));
                        fn();
                    } else {
                        message.error("Login Failed. Please try again later.", 3);
                    }
                })
                .catch((error: any) => {
                    message.error(`${error.code}: ${error.message}`, 3);
                });
        } else {
            message.error("Login Failed. Please try again later.", 3);
        }
    };

    triggerTokenExchange = (targetId: string) => {
        // response modal: { access_token: "xxxxxxxxx-xxxx-xxxx" }
        return this._axioInstance.post(
            `/realms/${this._realmsName}/protocol/openid-connect/token`,
            {
                client_id: this._clientId,
                client_secret: this._clientSecret,
                grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
                requested_token_type: "urn:ietf:params:oauth:token-type:access_token",
                audience: targetId,
                subject_token: getToken() || "",
            },
            {
                responseType: "json",
            }
        );
    };

    triggerRefreshToken = () => {
        return this._axioInstance.post(
            `/realms/${this._realmsName}/protocol/openid-connect/token`,
            {
                grant_type: "refresh_token",
                client_id: this._clientId,
                client_secret: this._clientSecret,
                code: getRefreshToken() || "",
            },
            {
                responseType: "json",
            }
        );
    };

    triggerLogout = () => {
        this._axioInstance.get(`${this._keyClockDomain}/realms/${this._realmsName}/protocol/openid-connect/logout`, {
            id_token_hint: defaultIfEmptyOrNull(getToken(), ""),
            post_logout_redirect_uri: window.location.origin,
        });
    };
}

export default KeyClockHelper;
