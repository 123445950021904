import { useEffect, useState } from "react";
import { Button, Descriptions } from "antd";
import { useNavigate } from "react-router-dom";
import { User } from "../../constants/type";
import CardBox from "../../components/Common/CardBox";
import { DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler } from "../../utils/Common";
import LoadingComponent from "../../components/Loading";
import { apiRequest, APIs } from "../../services/apiConfig";
import { SUCCESS_FAILED } from "../../constants";

function MyAccountPage() {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<User>();

    useEffect(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_USER_PROFILE_BY_ID, {})
            .then((res: any) => {
                setData(res as User);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("my account data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
        return () => {};
    }, []);

    return isLoading ? (
        <div className="loading-container">
            <LoadingComponent tip="Loading..." />
        </div>
    ) : (
        <CardBox
            title={"My Profile"}
            extra={
                <Button
                    type="primary"
                    style={{
                        width: "auto",
                    }}
                    onClick={() => navigate("/myaccount/edit", { state: data })}
                >
                    Update Profile
                </Button>
            }
        >
            <div className="my-profile-container">
                <Descriptions bordered column={1} labelStyle={{ width: "16.275vw" }}>
                    <Descriptions.Item label="Username">{data?.username ? data.username : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Email">{data?.email ? data.email : "-"}</Descriptions.Item>
                    <Descriptions.Item label="First Name">{data?.firstName ? data.firstName : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Last Name">{data?.lastName ? data.lastName : "-"}</Descriptions.Item>
                    <Descriptions.Item label="User Role">{data?.roles ? data.roles.split(",").join(", ") : "-"}</Descriptions.Item>
                    <Descriptions.Item label="User Team">{data?.team ? data.team : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Last Logged In (Local)">
                        {data?.lastLoginUtc ? DataTableColumnRender.DateTime(data.lastLoginUtc) : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Created At (Local)">
                        {data?.createdDateUtc ? DataTableColumnRender.DateTime(data.createdDateUtc) : "-"}
                    </Descriptions.Item>
                </Descriptions>
            </div>
        </CardBox>
    );
}

export default MyAccountPage;
